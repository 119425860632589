<template>
	<div>
		<el-card :style="{width:width,height:height}" shadow = "never" class="box" body-style="padding:0px;width:100%">
			<div class="fullbox" v-if="ispush" style="cursor: pointer;" @click="handleClick">
				<i class="iconplus el-icon-plus"></i>
				<div class="iconplus-font">创建规则</div>
			</div>
			<div class="fullbox" v-else>
				<div class="allthebox">
					<div class="topblue"></div>
					<div class="bottombox">
						<div class="flexRow" style="justify-content: space-between">
							<div class="title fonthidden" style="width:150px">
								{{itemData.title}}
							</div>
							<div style="font-size:12px;color:#409eff">[{{itemData.tag}}]</div>
							<!-- <el-tag effect="plain">{{itemData.tag}}</el-tag> -->
						</div>
						<div style="margin:10px 0px 5px 0px" class="middle-grayfont fontvertical2">
							{{itemData.description}}
						</div>
						<div class="flexRow" style="align-items:flex-end;justify-content: space-between">
							<div>
								<div v-if="itemData.showCount">
									<el-link type="primary" v-if="searchState===1" @click="handleSearch">查看人数</el-link>
									<div v-if="searchState===2"  style="font-size:14px;color:#303133;">统计中...</div>
									<div v-if="searchState===3"  style="font-size:14px;color:#303133;">{{memberCount}}人</div>
								</div>
							</div>
							
							<el-button type="primary" @click="handleClick" plain style="border:none">创建规则</el-button>
						</div>
					</div>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
	
	import {templateMemberCount, getTemplateParam} from '@/api/sv1.0.0.js';
	
	export default {
		props: {
			width: {
				type: String,
				default: '280px'
			},
			height: {
				type: String,
				default: '170px'
			},
			ispush: {
				type: Boolean,
				default: false
			},
			itemData: {
				type: Object,
				default: () => {
					return {}
				}
			}
		},
		data(){
			return {
				memberCount: 0,
				searchState: 1
			}
		},
		methods: {
			handleClick() {
				this.$emit('click')
			},
			handleSearch(){
				this.searchState = 2;
				this.getParams(this.itemData.templateId).then(res=>{
					this.search(res);
				})
				
			},
			async search(res){
				try{
					this.searchState = 2;
					
					var config = JSON.parse(JSON.stringify(this.itemData.data));
					if(this.itemData.templateId === 6){
						var num = res.CommissionMemberCount ? +(res.CommissionAwardMoney / res.CommissionMemberCount).toFixed(2) : 0;
						num = num===0?'0':num;
					// console.log(config)
						config.TagRuleConfigDetailList[1].Num = num;
					}
					if(this.itemData.templateId === 7){
						var num = res.PayOrderMemberCount ? +(res.PayOrderCount / res.PayOrderMemberCount).toFixed(2) : 0;
						var num1 = res.PayOrderMemberCount ? +(res.PayOrderMoney / res.PayOrderMemberCount).toFixed(2) : 0;
						num = num===0?'0':num;
						num1 = num1===0?'0':num1;
						config.TagRuleConfigDetailList[1].Num = Math.floor(+num);;
						config.TagRuleConfigDetailList[0].Num = num1;
					}
					if(this.itemData.templateId === 11){
						var num = res.GrowthValue === 0 ? '0':res.GrowthValue;
						config.TagRuleConfigDetailList[1].Num = res.GrowthValue;
					}
					
					const result = await templateMemberCount({
						TagRuleConfig: config
					})
					this.memberCount = result.Result.MemberCount;
					this.searchState = 3;
				}catch(e){
					this.searchState = 1;
				}
			},
			getParams(templateId){
				// console.log(templateId)
				return new Promise(async (reslove, reject)=>{
					try{
						
						var type = 0;
						if (templateId == 6){
							type = 3;
						}
						if (templateId == 7){
							type = 1;
						}
						if (templateId == 11){
							type = 2;
						}
						
						const res = await getTemplateParam({
							TemplateType: type
						});
						// console.log(res)
						reslove(res);
					}catch(e){
						this.searchState = 1;
						reject()
					}
				})
			},
		},
	}
</script>

<style lang="less" scoped>
	.box {
		display: flex;
	}

	.fullbox {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.iconplus {
		font-size: 18px;
		color: #409eff;
	}

	.iconplus-font {
		font-size: 16px;
		color: #409eff;
		margin-left: 5px
	}

	.allthebox {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;

		.topblue {
			background: #409eff;
			height: 4px;
		}

		.bottombox {
			width: 100%;
			flex: 1;
			/* border:1px solid black; */
			padding: 25px 15px 10px 15px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.title {
				font-weight: bold;
				font-size: 16px;
				color: #303133;
			}

			.middle-grayfont {
				font-size: 12px;
				color: #999999;
				line-height: 1.5;
			}
		}
	}

	.flexRow {
		display: flex;
		flex-direction: row;
		align-items: center;
		/* justify-content: space-between; */
	}

	.fonthidden {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.fontvertical2 {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
	}
</style>
<style>

</style>
