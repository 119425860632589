export const templateList = [
{
	templateId: 1,
	title: '高频消费',
	description: '近30天消费次数大于3次，消费金额大于500元的客户',
	tag: '每月更新',
	showCount: true,
	data: {
			"Name": "高频消费",
			"State": 1,
			"UpdateWay": 1,
			"IsNeedUpdate": false,
			"AutoUpdatePeriodType": 2,
			"AutoUpdatePeriodNum": 1,
			"TagRuleConfigDetailList": [{
				"TagRuleConfigId": 369,
				"Type": 10,
				"TimeType": 1,
				"DynamicTimeType": 10,
				"ConditionType": 2,
				"Num": 3.00,
				"MaxNum": 0.00,
				"OverDayNum": 0,
				"ProductRangeType": 0,
				"PageRangeType": 0,
				"ActivityBehaviorType": 0,
				"RuleRemark": "",
				"TagRuleConfigDetailRelationIdList": [],
				"ConditionTypeValue": "大于等于",
				"Id": 669,
				"Delstatus": false,
				"AddTime": "2021-11-22 14:40:40",
				"UpdateTime": "2021-11-22 14:40:40",
				"MallId": 92
			}, {
				"TagRuleConfigId": 369,
				"Type": 11,
				"TimeType": 1,
				"DynamicTimeType": 10,
				"ConditionType": 2,
				"Num": 500.00,
				"MaxNum": 0.00,
				"OverDayNum": 0,
				"ProductRangeType": 0,
				"PageRangeType": 0,
				"ActivityBehaviorType": 0,
				"RuleRemark": "",
				"TagRuleConfigDetailRelationIdList": [],
				"ConditionTypeValue": "大于等于",
				"Id": 670,
				"Delstatus": false,
				"AddTime": "2021-11-22 14:40:40",
				"UpdateTime": "2021-11-22 14:40:40",
				"MallId": 92
			}],
			"Id": 369,
			"Delstatus": false,
			"AddTime": "2021-11-22 14:40:40",
			"UpdateTime": "2021-11-22 14:40:40",
			"MallId": 92
	}
},

{
	templateId: 2,
	title: '有购买意向的新客',
	description: '近7天浏览页面次数超过5次，但是没有支付成功过的新客户',
	tag: '每周更新',
	showCount: true,
	data: {
			"Name": "有购买意向的新客",
			"State": 1,
			"UpdateWay": 1,
			"IsNeedUpdate": false,
			"AutoUpdatePeriodType": 1,
			"AutoUpdatePeriodNum": 1,
			"TagRuleConfigDetailList": [{
				"TagRuleConfigId": 435,
				"Type": 100,
				"TimeType": 0,
				"DynamicTimeType": 0,
				"ConditionType": 0,
				"Num": 0.00,
				"MaxNum": 0.00,
				"OverDayNum": 0,
				"ProductRangeType": 0,
				"PageRangeType": 0,
				"ActivityBehaviorType": 0,
				"RuleRemark": "",
				"TagRuleConfigDetailRelationIdList": [{
					"TagRuleConfigId": 435,
					"TagRuleConfigDetailId": 777,
					"RelationId": 0,
					"RelationValue": "",
					"PagePath": "",
					"PageName": "",
					"ProductImgUrlComplete": "",
					"Id": 615,
					"Delstatus": false,
					"AddTime": "2021-11-23 10:31:48",
					"UpdateTime": "2021-11-23 10:31:48",
					"MallId": 92
				}],
				"ConditionTypeValue": "0",
				"Id": 777,
				"Delstatus": false,
				"AddTime": "2021-11-23 10:31:48",
				"UpdateTime": "2021-11-23 10:31:48",
				"MallId": 92
			}, {
				"TagRuleConfigId": 435,
				"Type": 300,
				"TimeType": 1,
				"DynamicTimeType": 8,
				"ConditionType": 2,
				"Num": 5.00,
				"MaxNum": 0.00,
				"OverDayNum": 0,
				"ProductRangeType": 0,
				"PageRangeType": 0,
				"ActivityBehaviorType": 0,
				"RuleRemark": "",
				"TagRuleConfigDetailRelationIdList": [],
				"ConditionTypeValue": "大于等于",
				"Id": 778,
				"Delstatus": false,
				"AddTime": "2021-11-23 10:31:48",
				"UpdateTime": "2021-11-23 10:31:48",
				"MallId": 92
			}],
			"Id": 435,
			"Delstatus": false,
			"AddTime": "2021-11-23 10:31:48",
			"UpdateTime": "2021-11-23 10:31:48",
			"MallId": 92
	}
},

{
	templateId: 3,
	title: '活跃客户',
	description: '近7天浏览页面次数超过10次的客户',
	tag: '每周更新',
	showCount: true,
	data: {
			"Name": "活跃客户",
			"State": 1,
			"UpdateWay": 1,
			"IsNeedUpdate": false,
			"LastUpdateTime": "2021-11-22 15:13:50",
			"AutoUpdatePeriodType": 1,
			"AutoUpdatePeriodNum": 1,
			"TagRuleConfigDetailList": [{
				"TagRuleConfigId": 373,
				"Type": 300,
				"TimeType": 1,
				"DynamicTimeType": 8,
				"ConditionType": 2,
				"Num": 10.00,
				"MaxNum": 0.00,
				"OverDayNum": 0,
				"ProductRangeType": 0,
				"PageRangeType": 0,
				"ActivityBehaviorType": 0,
				"RuleRemark": "",
				"TagRuleConfigDetailRelationIdList": [],
				"ConditionTypeValue": "大于等于",
				"Id": 674,
				"Delstatus": false,
				"AddTime": "2021-11-22 14:46:55",
				"UpdateTime": "2021-11-22 14:46:55",
				"MallId": 92
			}],
			"Id": 373,
			"Delstatus": false,
			"AddTime": "2021-11-22 14:46:55",
			"UpdateTime": "2021-11-22 14:46:55",
			"MallId": 92
	}
},

{
	templateId: 4,
	title: '即将流失新客',
	description: '近30天引入的新客，近7天没有再访问过商城',
	tag: '每周更新',
	showCount: true,
	data: {
			"Name": "即将流失新客",
			"State": 1,
			"UpdateWay": 1,
			"IsNeedUpdate": false,
			"LastUpdateTime": "2021-11-22 15:13:50",
			"AutoUpdatePeriodType": 1,
			"AutoUpdatePeriodNum": 1,
			"TagRuleConfigDetailList": [{
				"TagRuleConfigId": 375,
				"Type": 100,
				"TimeType": 0,
				"DynamicTimeType": 0,
				"ConditionType": 0,
				"Num": 0.00,
				"MaxNum": 0.00,
				"OverDayNum": 0,
				"ProductRangeType": 0,
				"PageRangeType": 0,
				"ActivityBehaviorType": 0,
				"RuleRemark": "",
				"TagRuleConfigDetailRelationIdList": [{
					"TagRuleConfigId": 375,
					"TagRuleConfigDetailId": 678,
					"RelationId": 0,
					"PagePath": "",
					"PageName": "",
					"ProductImgUrlComplete": "",
					"Id": 589,
					"Delstatus": false,
					"AddTime": "2021-11-22 14:50:18",
					"UpdateTime": "2021-11-22 14:50:18",
					"MallId": 92
				}],
				"ConditionTypeValue": "0",
				"Id": 678,
				"Delstatus": false,
				"AddTime": "2021-11-22 14:50:18",
				"UpdateTime": "2021-11-22 14:50:18",
				"MallId": 92
			}, {
				"TagRuleConfigId": 375,
				"Type": 4,
				"TimeType": 1,
				"DynamicTimeType": 10,
				"ConditionType": 0,
				"Num": 0.00,
				"MaxNum": 0.00,
				"OverDayNum": 0,
				"ProductRangeType": 0,
				"PageRangeType": 0,
				"ActivityBehaviorType": 0,
				"RuleRemark": "",
				"TagRuleConfigDetailRelationIdList": [],
				"ConditionTypeValue": "0",
				"Id": 679,
				"Delstatus": false,
				"AddTime": "2021-11-22 14:50:18",
				"UpdateTime": "2021-11-22 14:50:18",
				"MallId": 92
			}, {
				"TagRuleConfigId": 375,
				"Type": 300,
				"TimeType": 1,
				"DynamicTimeType": 8,
				"ConditionType": 5,
				"Num": 0.00,
				"MaxNum": 0.00,
				"OverDayNum": 0,
				"ProductRangeType": 0,
				"PageRangeType": 0,
				"ActivityBehaviorType": 0,
				"RuleRemark": "",
				"TagRuleConfigDetailRelationIdList": [],
				"ConditionTypeValue": "等于",
				"Id": 680,
				"Delstatus": false,
				"AddTime": "2021-11-22 14:50:18",
				"UpdateTime": "2021-11-22 14:50:18",
				"MallId": 92
			}],
			"Id": 375,
			"Delstatus": false,
			"AddTime": "2021-11-22 14:50:18",
			"UpdateTime": "2021-11-22 14:50:18",
			"MallId": 92
	}
},

{
	templateId: 5,
	title: '即将流失老客',
	description: '近30天未再下单的老客户',
	tag: '每月更新',
	showCount: true,
	data:{
			"Name": "即将流失老客",
			"State": 1,
			"UpdateWay": 1,
			"IsNeedUpdate": false,
			"AutoUpdatePeriodType": 2,
			"AutoUpdatePeriodNum": 1,
			"TagRuleConfigDetailList": [{
				"TagRuleConfigId": 376,
				"Type": 100,
				"TimeType": 0,
				"DynamicTimeType": 0,
				"ConditionType": 0,
				"Num": 0.00,
				"MaxNum": 0.00,
				"OverDayNum": 0,
				"ProductRangeType": 0,
				"PageRangeType": 0,
				"ActivityBehaviorType": 0,
				"RuleRemark": "",
				"TagRuleConfigDetailRelationIdList": [{
					"TagRuleConfigId": 376,
					"TagRuleConfigDetailId": 681,
					"RelationId": 1,
					"PagePath": "",
					"PageName": "",
					"ProductImgUrlComplete": "",
					"Id": 591,
					"Delstatus": false,
					"AddTime": "2021-11-22 14:51:02",
					"UpdateTime": "2021-11-22 14:51:02",
					"MallId": 92
				}],
				"ConditionTypeValue": "0",
				"Id": 681,
				"Delstatus": false,
				"AddTime": "2021-11-22 14:51:02",
				"UpdateTime": "2021-11-22 14:51:02",
				"MallId": 92
			}, {
				"TagRuleConfigId": 376,
				"Type": 8,
				"TimeType": 1,
				"DynamicTimeType": 10,
				"ConditionType": 5,
				"Num": 0.00,
				"MaxNum": 0.00,
				"OverDayNum": 0,
				"ProductRangeType": 0,
				"PageRangeType": 0,
				"ActivityBehaviorType": 0,
				"RuleRemark": "",
				"TagRuleConfigDetailRelationIdList": [],
				"ConditionTypeValue": "等于",
				"Id": 682,
				"Delstatus": false,
				"AddTime": "2021-11-22 14:51:02",
				"UpdateTime": "2021-11-22 14:51:02",
				"MallId": 92
			}],
			"Id": 376,
			"Delstatus": false,
			"AddTime": "2021-11-22 14:51:02",
			"UpdateTime": "2021-11-22 14:51:02",
			"MallId": 92
	}
},

{
	templateId: 6,
	title: '高质量推手',
	description: '近30天累计收益高于商城所有推手收益平均值的推手',
	tag: '每月更新',
	showCount: true,
	data: {
			"Name": "高质量推手",
			"State": 1,
			"UpdateWay": 1,
			"IsNeedUpdate": false,
			"AutoUpdatePeriodType": 2,
			"AutoUpdatePeriodNum": 1,
			"TagRuleConfigDetailList": [{
				"TagRuleConfigId": 377,
				"Type": 3,
				"TimeType": 0,
				"DynamicTimeType": 0,
				"ConditionType": 0,
				"Num": 0.00,
				"MaxNum": 0.00,
				"OverDayNum": 0,
				"ProductRangeType": 0,
				"PageRangeType": 0,
				"ActivityBehaviorType": 0,
				"RuleRemark": "",
				"TagRuleConfigDetailRelationIdList": [],
				"ConditionTypeValue": "0",
				"Id": 683,
				"Delstatus": false,
				"AddTime": "2021-11-22 14:51:51",
				"UpdateTime": "2021-11-22 14:51:51",
				"MallId": 92
			}, {
				"TagRuleConfigId": 377,
				"Type": 500,
				"TimeType": 1,
				"DynamicTimeType": 10,
				"ConditionType": 1,
				"Num": 500.00,
				"MaxNum": 0.00,
				"OverDayNum": 0,
				"ProductRangeType": 0,
				"PageRangeType": 0,
				"ActivityBehaviorType": 0,
				"RuleRemark": "",
				"TagRuleConfigDetailRelationIdList": [],
				"ConditionTypeValue": "大于",
				"Id": 684,
				"Delstatus": false,
				"AddTime": "2021-11-22 14:51:51",
				"UpdateTime": "2021-11-22 14:51:51",
				"MallId": 92
			}],
			"Id": 377,
			"Delstatus": false,
			"AddTime": "2021-11-22 14:51:51",
			"UpdateTime": "2021-11-22 14:51:51",
			"MallId": 92
	}
},

{
	templateId: 7,
	title: '重点发展客户',
	description: '近30天有过支付，并且订单数和笔单价都高于商城平均值的老客户',
	tag: '每月更新',
	showCount: true,
	data: {
			"Name": "重点发展客户",
			"State": 1,
			"UpdateWay": 1,
			"IsNeedUpdate": false,
			"AutoUpdatePeriodType": 2,
			"AutoUpdatePeriodNum": 1,
			"TagRuleConfigDetailList": [{
				"TagRuleConfigId": 379,
				"Type": 7,
				"TimeType": 0,
				"DynamicTimeType": 0,
				"ConditionType": 2,
				"Num": 500.00,
				"MaxNum": 0.00,
				"OverDayNum": 0,
				"ProductRangeType": 0,
				"PageRangeType": 0,
				"ActivityBehaviorType": 0,
				"RuleRemark": "",
				"TagRuleConfigDetailRelationIdList": [],
				"ConditionTypeValue": "大于等于",
				"Id": 686,
				"Delstatus": false,
				"AddTime": "2021-11-22 14:54:07",
				"UpdateTime": "2021-11-22 14:54:07",
				"MallId": 92
			}, {
				"TagRuleConfigId": 379,
				"Type": 10,
				"TimeType": 1,
				"DynamicTimeType": 10,
				"ConditionType": 2,
				"Num": 3.00,
				"MaxNum": 0.00,
				"OverDayNum": 0,
				"ProductRangeType": 0,
				"PageRangeType": 0,
				"ActivityBehaviorType": 0,
				"RuleRemark": "",
				"TagRuleConfigDetailRelationIdList": [],
				"ConditionTypeValue": "大于等于",
				"Id": 687,
				"Delstatus": false,
				"AddTime": "2021-11-22 14:54:07",
				"UpdateTime": "2021-11-22 14:54:07",
				"MallId": 92
			}],
			"Id": 379,
			"Delstatus": false,
			"AddTime": "2021-11-22 14:54:07",
			"UpdateTime": "2021-11-22 14:54:07",
			"MallId": 92
	}
},

{
	templateId: 8,
	title: '高频充值',
	description: '近30天充值次数大于3次的客户',
	tag: '每月更新',
	showCount: true,
	data: {
			"Name": "高频充值",
			"State": 1,
			"UpdateWay": 1,
			"IsNeedUpdate": false,
			"AutoUpdatePeriodType": 2,
			"AutoUpdatePeriodNum": 1,
			"TagRuleConfigDetailList": [{
				"TagRuleConfigId": 380,
				"Type": 14,
				"TimeType": 1,
				"DynamicTimeType": 10,
				"ConditionType": 1,
				"Num": 3.00,
				"MaxNum": 0.00,
				"OverDayNum": 0,
				"ProductRangeType": 0,
				"PageRangeType": 0,
				"ActivityBehaviorType": 0,
				"RuleRemark": "",
				"TagRuleConfigDetailRelationIdList": [],
				"ConditionTypeValue": "大于",
				"Id": 688,
				"Delstatus": false,
				"AddTime": "2021-11-22 14:54:47",
				"UpdateTime": "2021-11-22 14:54:47",
				"MallId": 92
			}],
			"Id": 380,
			"Delstatus": false,
			"AddTime": "2021-11-22 14:54:47",
			"UpdateTime": "2021-11-22 14:54:47",
			"MallId": 92
	}
},

{
	templateId: 9,
	title: '潜力推手',
	description: '近7天分享页面次数超过5次的非推手',
	tag: '每周更新',
	showCount: true,
	data: {
			"Name": "潜力推手",
			"State": 1,
			"UpdateWay": 1,
			"IsNeedUpdate": false,
			"AutoUpdatePeriodType": 1,
			"AutoUpdatePeriodNum": 1,
			"TagRuleConfigDetailList": [{
				"TagRuleConfigId": 788,
				"Type": 3,
				"TimeType": 0,
				"DynamicTimeType": 0,
				"ConditionType": 0,
				"Num": 0.00,
				"MaxNum": 0.00,
				"OverDayNum": 0,
				"ProductRangeType": 0,
				"PageRangeType": 0,
				"ActivityBehaviorType": 0,
				"RuleRemark": "",
				"TagRuleConfigDetailRelationIdList": [{
					"TagRuleConfigId": 788,
					"TagRuleConfigDetailId": 1271,
					"RelationId": 0,
					"RelationValue": "",
					"PagePath": "",
					"PageName": "",
					"ProductImgUrlComplete": "",
					"Id": 949,
					"Delstatus": false,
					"AddTime": "2021-12-03 13:52:32",
					"UpdateTime": "2021-12-03 13:52:32",
					"MallId": 92
				}],
				"ConditionTypeValue": "0",
				"Id": 1271,
				"Delstatus": false,
				"AddTime": "2021-12-03 13:52:32",
				"UpdateTime": "2021-12-03 13:52:32",
				"MallId": 92
			}, {
				"TagRuleConfigId": 788,
				"Type": 302,
				"TimeType": 1,
				"DynamicTimeType": 8,
				"ConditionType": 1,
				"Num": 5.00,
				"MaxNum": 0.00,
				"OverDayNum": 0,
				"ProductRangeType": 0,
				"PageRangeType": 0,
				"ActivityBehaviorType": 0,
				"RuleRemark": "",
				"TagRuleConfigDetailRelationIdList": [],
				"ConditionTypeValue": "大于",
				"Id": 1272,
				"Delstatus": false,
				"AddTime": "2021-12-03 13:52:32",
				"UpdateTime": "2021-12-03 13:52:32",
				"MallId": 92
			}],
			"Id": 788,
			"Delstatus": false,
			"AddTime": "2021-12-03 13:52:32",
			"UpdateTime": "2021-12-03 13:52:32",
			"MallId": 92
	}
},

{
	templateId: 10,
	title: '活跃推手',
	description: '近7天分享页面次数超过10次的推手',
	tag: '每周更新',
	showCount: true,
	data: {
			"Name": "活跃推手",
			"State": 1,
			"UpdateWay": 1,
			"IsNeedUpdate": false,
			"AutoUpdatePeriodType": 1,
			"AutoUpdatePeriodNum": 1,
			"TagRuleConfigDetailList": [{
				"TagRuleConfigId": 789,
				"Type": 3,
				"TimeType": 0,
				"DynamicTimeType": 0,
				"ConditionType": 0,
				"Num": 0.00,
				"MaxNum": 0.00,
				"OverDayNum": 0,
				"ProductRangeType": 0,
				"PageRangeType": 0,
				"ActivityBehaviorType": 0,
				"RuleRemark": "",
				"TagRuleConfigDetailRelationIdList": [],
				"ConditionTypeValue": "0",
				"Id": 1273,
				"Delstatus": false,
				"AddTime": "2021-12-03 13:54:04",
				"UpdateTime": "2021-12-03 13:54:04",
				"MallId": 92
			}, {
				"TagRuleConfigId": 789,
				"Type": 302,
				"TimeType": 1,
				"DynamicTimeType": 8,
				"ConditionType": 1,
				"Num": 10.00,
				"MaxNum": 0.00,
				"OverDayNum": 0,
				"ProductRangeType": 0,
				"PageRangeType": 0,
				"ActivityBehaviorType": 0,
				"RuleRemark": "",
				"TagRuleConfigDetailRelationIdList": [],
				"ConditionTypeValue": "大于",
				"Id": 1274,
				"Delstatus": false,
				"AddTime": "2021-12-03 13:54:04",
				"UpdateTime": "2021-12-03 13:54:04",
				"MallId": 92
			}],
			"Id": 789,
			"Delstatus": false,
			"AddTime": "2021-12-03 13:54:04",
			"UpdateTime": "2021-12-03 13:54:04",
			"MallId": 92
	}
},

{
	templateId: 11,
	title: '待转化会员',
	description: '已经达到最低级别会员的成长值门槛，但是还没有开通会员身份',
	tag: '每周更新',
	showCount: true,
	data: {
			"Name": "待转化会员",
			"State": 1,
			"UpdateWay": 1,
			"IsNeedUpdate": false,
			"AutoUpdatePeriodType": 1,
			"AutoUpdatePeriodNum": 1,
			"TagRuleConfigDetailList": [{
				"TagRuleConfigId": 398,
				"Type": 2,
				"TimeType": 0,
				"DynamicTimeType": 0,
				"ConditionType": 0,
				"Num": 0.00,
				"MaxNum": 0.00,
				"OverDayNum": 0,
				"ProductRangeType": 0,
				"PageRangeType": 0,
				"ActivityBehaviorType": 0,
				"RuleRemark": "",
				"TagRuleConfigDetailRelationIdList": [{
					"TagRuleConfigId": 398,
					"TagRuleConfigDetailId": 736,
					"RelationId": 0,
					"PagePath": "",
					"PageName": "",
					"ProductImgUrlComplete": "",
					"Id": 605,
					"Delstatus": false,
					"AddTime": "2021-11-22 16:32:56",
					"UpdateTime": "2021-11-22 16:32:56",
					"MallId": 92
				}],
				"ConditionTypeValue": "0",
				"Id": 736,
				"Delstatus": false,
				"AddTime": "2021-11-22 16:32:56",
				"UpdateTime": "2021-11-22 16:32:56",
				"MallId": 92
			}, {
				"TagRuleConfigId": 398,
				"Type": 101,
				"TimeType": 0,
				"DynamicTimeType": 0,
				"ConditionType": 2,
				"Num": 0.00,
				"MaxNum": 0.00,
				"OverDayNum": 0,
				"ProductRangeType": 0,
				"PageRangeType": 0,
				"ActivityBehaviorType": 0,
				"RuleRemark": "",
				"TagRuleConfigDetailRelationIdList": [],
				"ConditionTypeValue": "大于等于",
				"Id": 737,
				"Delstatus": false,
				"AddTime": "2021-11-22 16:32:56",
				"UpdateTime": "2021-11-22 16:32:56",
				"MallId": 92
			}],
			"Id": 398,
			"Delstatus": false,
			"AddTime": "2021-11-22 16:32:56",
			"UpdateTime": "2021-11-22 16:32:56",
			"MallId": 92
	}
},

{
	templateId: 12,
	title: '浏览过某商品',
	description: '近7天浏览过某商品或某品牌或某分组下的商品，超出2次',
	tag: '每周更新',
	showCount: false,
	data: {
			"Name": "浏览过某商品",
			"State": 1,
			"UpdateWay": 1,
			"IsNeedUpdate": false,
			"LastUpdateTime": "2021-11-22 17:30:19",
			"AutoUpdatePeriodType": 1,
			"AutoUpdatePeriodNum": 1,
			"TagRuleConfigDetailList": [{
				"TagRuleConfigId": 405,
				"Type": 301,
				"TimeType": 1,
				"DynamicTimeType": 8,
				"ConditionType": 1,
				"Num": 2.00,
				"MaxNum": 0.00,
				"OverDayNum": 0,
				"ProductRangeType": 0,
				"PageRangeType": 0,
				"ActivityBehaviorType": 0,
				"RuleRemark": "",
				"TagRuleConfigDetailRelationIdList": [],
				"ConditionTypeValue": "大于",
				"Id": 745,
				"Delstatus": false,
				"AddTime": "2021-11-22 17:24:40",
				"UpdateTime": "2021-11-22 17:24:40",
				"MallId": 92
			}],
			"Id": 405,
			"Delstatus": false,
			"AddTime": "2021-11-22 17:24:40",
			"UpdateTime": "2021-11-22 17:24:40",
			"MallId": 92
	}
},

{
	templateId: 13,
	title: '购买过某商品',
	description: '近30天购买过某商品或某品牌或某分组下的商品',
	tag: '每月更新',
	showCount: false,
	data: {
			"Name": "购买过某商品",
			"State": 1,
			"UpdateWay": 1,
			"IsNeedUpdate": false,
			"AutoUpdatePeriodType": 2,
			"AutoUpdatePeriodNum": 1,
			"TagRuleConfigDetailList": [{
				"TagRuleConfigId": 406,
				"Type": 15,
				"TimeType": 1,
				"DynamicTimeType": 10,
				"ConditionType": 2,
				"Num": 1.00,
				"MaxNum": 0.00,
				"OverDayNum": 0,
				"ProductRangeType": 0,
				"PageRangeType": 0,
				"ActivityBehaviorType": 0,
				"RuleRemark": "",
				"TagRuleConfigDetailRelationIdList": [],
				"ConditionTypeValue": "大于等于",
				"Id": 746,
				"Delstatus": false,
				"AddTime": "2021-11-22 17:25:11",
				"UpdateTime": "2021-11-22 17:25:11",
				"MallId": 92
			}],
			"Id": 406,
			"Delstatus": false,
			"AddTime": "2021-11-22 17:25:11",
			"UpdateTime": "2021-11-22 17:25:11",
			"MallId": 92
	}
},

{
	templateId: 14,
	title: '搜索关键词',
	description: '近7天搜索过某关键词的客户',
	tag: '每周更新',
	showCount: false,
	data: {
			"Name": "搜索关键词",
			"State": 1,
			"UpdateWay": 1,
			"IsNeedUpdate": false,
			"AutoUpdatePeriodType": 1,
			"AutoUpdatePeriodNum": 1,
			"TagRuleConfigDetailList": [{
				"TagRuleConfigId": 421,
				"Type": 304,
				"TimeType": 1,
				"DynamicTimeType": 8,
				"ConditionType": 1,
				"Num": 2.00,
				"MaxNum": 0.00,
				"OverDayNum": 0,
				"ProductRangeType": 0,
				"PageRangeType": 0,
				"ActivityBehaviorType": 0,
				"RuleRemark": "",
				"TagRuleConfigDetailRelationIdList": [],
				"ConditionTypeValue": "大于",
				"Id": 761,
				"Delstatus": false,
				"AddTime": "2021-11-22 19:19:09",
				"UpdateTime": "2021-11-22 19:19:09",
				"MallId": 92
			}],
			"Id": 421,
			"Delstatus": false,
			"AddTime": "2021-11-22 19:19:09",
			"UpdateTime": "2021-11-22 19:19:09",
			"MallId": 92
	}
},

{
	templateId: 15,
	title: '优惠券客户',
	description: '近30天成功下单，并且优惠券使用超出2次的客户',
	tag: '每月更新',
	showCount: true,
	data: {
			"Name": "优惠券客户",
			"State": 1,
			"UpdateWay": 1,
			"IsNeedUpdate": false,
			"AutoUpdatePeriodType": 2,
			"AutoUpdatePeriodNum": 1,
			"TagRuleConfigDetailList": [{
				"TagRuleConfigId": 383,
				"Type": 401,
				"TimeType": 1,
				"DynamicTimeType": 10,
				"ConditionType": 1,
				"Num": 2.00,
				"MaxNum": 0.00,
				"OverDayNum": 0,
				"ProductRangeType": 0,
				"PageRangeType": 0,
				"ActivityBehaviorType": 0,
				"RuleRemark": "",
				"TagRuleConfigDetailRelationIdList": [],
				"ConditionTypeValue": "大于",
				"Id": 691,
				"Delstatus": false,
				"AddTime": "2021-11-22 14:56:40",
				"UpdateTime": "2021-11-22 14:56:40",
				"MallId": 92
			}],
			"Id": 383,
			"Delstatus": false,
			"AddTime": "2021-11-22 14:56:40",
			"UpdateTime": "2021-11-22 14:56:40",
			"MallId": 92
	}
},

{
	templateId: 16,
	title: '喜欢发起拼团',
	description: '近30天成功发起拼团超过2次的客户',
	tag: '每月更新',
	showCount: true,
	data: {
			"Name": "喜欢发起拼团",
			"State": 1,
			"UpdateWay": 1,
			"IsNeedUpdate": false,
			"AutoUpdatePeriodType": 2,
			"AutoUpdatePeriodNum": 1,
			"TagRuleConfigDetailList": [{
				"TagRuleConfigId": 384,
				"Type": 402,
				"TimeType": 1,
				"DynamicTimeType": 10,
				"ConditionType": 1,
				"Num": 2.00,
				"MaxNum": 0.00,
				"OverDayNum": 0,
				"ProductRangeType": 0,
				"PageRangeType": 0,
				"ActivityBehaviorType": 0,
				"RuleRemark": "",
				"TagRuleConfigDetailRelationIdList": [],
				"ConditionTypeValue": "大于",
				"Id": 692,
				"Delstatus": false,
				"AddTime": "2021-11-22 14:57:08",
				"UpdateTime": "2021-11-22 14:57:08",
				"MallId": 92
			}],
			"Id": 384,
			"Delstatus": false,
			"AddTime": "2021-11-22 14:57:08",
			"UpdateTime": "2021-11-22 14:57:08",
			"MallId": 92
	}
},

{
	templateId: 17,
	title: '喜欢发起助力',
	description: '近30天成功发起助力超过2次的客户',
	tag: '每月更新',
	showCount: true,
	data: {
			"Name": "喜欢发起助力",
			"State": 1,
			"UpdateWay": 1,
			"IsNeedUpdate": false,
			"AutoUpdatePeriodType": 2,
			"AutoUpdatePeriodNum": 1,
			"TagRuleConfigDetailList": [{
				"TagRuleConfigId": 385,
				"Type": 403,
				"TimeType": 1,
				"DynamicTimeType": 10,
				"ConditionType": 1,
				"Num": 2.00,
				"MaxNum": 0.00,
				"OverDayNum": 0,
				"ProductRangeType": 0,
				"PageRangeType": 0,
				"ActivityBehaviorType": 0,
				"RuleRemark": "",
				"TagRuleConfigDetailRelationIdList": [],
				"ConditionTypeValue": "大于",
				"Id": 693,
				"Delstatus": false,
				"AddTime": "2021-11-22 14:57:33",
				"UpdateTime": "2021-11-22 14:57:33",
				"MallId": 92
			}],
			"Id": 385,
			"Delstatus": false,
			"AddTime": "2021-11-22 14:57:33",
			"UpdateTime": "2021-11-22 14:57:33",
			"MallId": 92
	}
}
]